import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import ScrollToTop from "react-scroll-to-top";
import About from "./pages/About";
import Service from "./pages/Service";
import ServiceDetails from "./pages/ServiceDetails";
import Faq from "./pages/Faq";
import Contact from "./pages/Contact";
import NotFound from "./pages/NotFound";
import Document from "./pages/Document";
import RouteScrollToTop from "./elements/RouteScrollToTop";
import WebSoftwareServiceDetailPage from "./pages/services/web-software";
import MobilePrivacy from "./pages/documents/mobile-privacy";
import DesktopSoftwareServiceDetailPage from "./pages/services/desktop-software";
import GlobalApplicationServiceDetailPage from "./pages/services/global-applications";
import MobileSoftwareServiceDetailPage from "./pages/services/mobile-software";
import RoboticCodingServiceDetailPage from "./pages/services/robotic-coding";
import SoftwareDevelopmentProcessesServiceDetail from "./pages/services/software-development-processes";
import SecurityAndDataServiceDetailPage from "./pages/services/security-and-data-protection";
import SoftwareTrendsServiceDetailPage from "./pages/services/software-trends";
import DownloadCenter from "./pages/DownloadCenter";

//import createSitemap from "./lib/SitemapGenerator";

function App() {

  useEffect(() => {
    AOS.init({
      offset: 0,
      easing: "ease",
      once: true,
    });
    AOS.refresh();
  }, []);

  /* const pages = [
    { path: '/', changefreq: 'monthly', priority: 0.9 },
    { path: '/hakkimizda', changefreq: 'monthly', priority: 0.8 },
    { path: '/hizmetlerimiz', changefreq: 'monthly', priority: 0.7 },
    { path: '/iletisim', changefreq: 'monthly', priority: 0.6 },
    { path: '/global-pazar-uygulamalari', changefreq: 'monthly', priority: 0.5 },
    { path: '/web-yazilimlari', changefreq: 'monthly', priority: 0.5 },
    { path: '/masaustu-yazilimlari', changefreq: 'monthly', priority: 0.5 },
    { path: '/mobil-yazilim', changefreq: 'weekly', priority: 0.5 },
    { path: '/dokuman', changefreq: 'weekly', priority: 0.4 },
    { path: '/dokuman/mobil-uygulama-gizlilik-bildirimi', changefreq: 'weekly', priority: 0.4 },
    { path: '/indirme-merkezi', changefreq: 'weekly', priority: 0.4 },
    { path: '/robotik-yazilimlar', changefreq: 'monthly', priority: 0.4 },
    { path: '/yazilim-gelistirme-surecleri', changefreq: 'monthly', priority: 0.4 },
    { path: '/guvenlik-ve-veri-koruma', changefreq: 'monthly', priority: 0.4 },
    { path: '/yazilim-trendleri-ve-gelecegi', changefreq: 'monthly', priority: 0.4 },
    { path: '/sikca-sorulan-sorular', changefreq: 'monthly', priority: 0.3 },
    { path: '/404', changefreq: 'never', priority: 0.1 },
  ]; 

   const sitemapXML = createSitemap(pages);
   console.log(sitemapXML); */


  return (
    <BrowserRouter>
      <RouteScrollToTop />
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/hakkimizda' element={<About />} />
        <Route exact path='/hizmetlerimiz' element={<Service />} />
        <Route exact path="/">
          <Route path='global-pazar-uygulamalari' element={<ServiceDetails><GlobalApplicationServiceDetailPage /></ServiceDetails>} />
          <Route path='web-yazilimlari' element={<ServiceDetails><WebSoftwareServiceDetailPage /></ServiceDetails>} />
          <Route path='masaustu-yazilimlari' element={<ServiceDetails><DesktopSoftwareServiceDetailPage /></ServiceDetails>} />
          <Route path='mobil-yazilim' element={<ServiceDetails><MobileSoftwareServiceDetailPage /></ServiceDetails>} />
          <Route path='robotik-yazilimlar' element={<ServiceDetails><RoboticCodingServiceDetailPage /></ServiceDetails>} />
          <Route path='yazilim-gelistirme-surecleri' element={<ServiceDetails><SoftwareDevelopmentProcessesServiceDetail /></ServiceDetails>} />
          <Route path='guvenlik-ve-veri-koruma' element={<ServiceDetails><SecurityAndDataServiceDetailPage /></ServiceDetails>} />
          <Route path='yazilim-trendleri-ve-gelecegi' element={<ServiceDetails><SoftwareTrendsServiceDetailPage /></ServiceDetails>} />
        </Route>
        <Route exact path='/dokuman' element={<Document />} />
        <Route exact path='/dokuman/mobil-uygulama-gizlilik-bildirimi' element={<MobilePrivacy />} />
        <Route exact path='/indirme-merkezi' element={<DownloadCenter />} />
        <Route exact path='/sikca-sorulan-sorular' element={<Faq />} />
        <Route exact path='/iletisim' element={<Contact />} />
        <Route path="/404" element={<NotFound status={404} />} />
        <Route path="*" element={<Navigate status={404} to={'/404'} />} />
      </Routes>
      <ScrollToTop smooth color='#ff6c22' />
    </BrowserRouter>
  );
}

export default App;