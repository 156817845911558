import React, { Fragment, Suspense } from 'react'
import PageTitle from '../components/PageTitle';
import Preloader from "../elements/Preloader";
import DownloadCenterList from '../components/DownloadCenterList';
const Breadcrumb = React.lazy(() => import("../components/Breadcrumb"));
const FooterBottomOne = React.lazy(() => import("../components/FooterBottomOne"));
const FooterOne = React.lazy(() => import("../components/Footer"));
const Navbar = React.lazy(() => import("../components/Navbar"));
const SearchPopup = React.lazy(() => import("../elements/SearchPopup"));

const DownloadCenter = () => {
    return (
        <>
            <PageTitle title={"İndirme Merkezi"} />

            <Fragment>
                <Suspense fallback={<Preloader />}>
                    <SearchPopup />
                    <Navbar />
                    <Breadcrumb title={"İndirme Merkezi"} />
                    <div className='pd-top-120 pd-bottom-120'>
                        <DownloadCenterList />
                    </div>
                    <FooterOne />
                    <FooterBottomOne />
                </Suspense>
            </Fragment>
        </>
    )
}

export default DownloadCenter