import React from "react";
import { FaApple, FaLinux, FaWindows } from "react-icons/fa";
import { Link } from "react-router-dom";

const DownloadCenterList = () => {
  return (
    <div className="container">
      <table className="table table">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Uygulama</th>
            <th scope="col">İndirme Bağlantısı</th>
          </tr>
        </thead>
        <tbody>
          <tr className="m-2 align-middle">
            <th scope="row">1</th>
            <td className="m-2">RustDesk</td>
            <td className="flex ">
              <Link
                className="p-4"
                to="https://github.com/rustdesk/rustdesk/releases/download/1.3.7/rustdesk-1.3.7-x86_64.exe"
              >
                <FaWindows size={30} />
              </Link>
              <Link
                className=" p-4"
                to="https://github.com/rustdesk/rustdesk/releases/download/1.3.7/rustdesk-1.3.7-x86_64.dmg"
              >
                <FaApple size={30} />
              </Link>
              <Link
                className=" p-4"
                to="https://github.com/rustdesk/rustdesk/releases/download/1.3.7/rustdesk-1.3.7-x86_64.deb"
              >
                <FaLinux size={30} />
              </Link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default DownloadCenterList;
